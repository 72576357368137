.userMenuPopover{    
    min-width: 300px;
    vertical-align: bottom;
}
.userMenuPopover > .header{
    padding:.7rem;
    display: flex;
}
.userMenuPopover > .header > .MuiAvatar-root{
    width:60px;
    height:60px;
    display: inline-flex;
    margin-right:1rem;
}
.userMenuPopover > .header > span{
    display: inline-block;
}
.userMenuPopover > .header > .ssoContainer{
    flex-grow: 10;
    display: flex;
    justify-content: flex-end;
}
.userMenuPopover > .header > .ssoContainer > .sso{
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    font-size: 0.65rem;
    color: white;
    border-radius: 50%;
}
.userMenuPopover .company{
    color: var(--color-hc-gray);
    font-size: 0.9rem;
    line-height: 1.3rem;
}
.userMenuPopover .username{
    color: rgba(0,0,0,.87);
    font-weight: 500;;
}
.userMenuPopover .email{
    color: var(--color-hc-gray);
    font-size: 0.9rem;
    line-height: 0.9rem;
}
.userMenuPopover .buttonBar{
    text-align: center;
    border-top: 1px solid rgba(0,0,0,.1);
}
.userMenuPopover .buttonBar > nav{
    padding:0;
}
.notificationPopover > .MuiPaper-root{
    max-width: 600px;
}
.notificationPopover > div > div:not(.buttonBar){
    padding:1em 2em;
    border-bottom: 1px solid rgba(0,0,0,.1);
}
.notificationPopover > div > div .caption{
    display: flex;
    align-items: center;
    font-size: 1.3em;
    font-weight: 500;
}
.notificationPopover > div > div .caption svg{
    margin-right:.5em;
    color: rgba(0,0,0,0.56);
}
.notificationPopover > div > div .description{
    margin-left: .6em;
    margin-top: 1em;
    margin-bottom: 1rem;
    color: rgba(0,0,0,0.56);
}
.notificationPopover > div > div .bold{
    color: rgba(0,0,0,0.87);
}
