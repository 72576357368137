.helpContainer{
    /*min-width: 400px;
    min-height: calc(100vh - 200px);*/
    position: absolute;
  }
  .helpContainer.shrinked{
    min-width: 0;
    min-height: 0;
  }
  .helpContainer .help{
    padding: 1rem;
    width: 400px;
    background-color: rgba(250,250,250 ,1);
    position: fixed;
    top: 90px;
    right: -500px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    visibility: hidden;
    transform: translateX(0);
    transition: transform 100ms ease-in 300ms, max-height 100ms ease-in 300ms;
    overflow-y: auto;
    height: calc(100vh - 100px);
    max-height: 100%;
    z-index:9;
  }
  .helpContainer .help.show{
    visibility: visible;
    transform: translateX(-525px);
    transition: transform 600ms cubic-bezier(0.68, -0.55, 0.27, 1.55) 200ms, max-height 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55) 100ms;
    overflow-y: hidden;
    padding: 1rem 0.5rem 1rem 1rem;
  }
  .helpContainer .help > button .MuiSvgIcon-root{
    margin-right:0 !important;
  }
  .helpContainer .help.shrinked{
    transform: translateX(-180px) !important;
    max-height: 55px;
    overflow: hidden;
  }
  .helpContainer .help > button{
    position: absolute;
    top: 12px;
    left:10px;
  }
  .helpContainer .help .header .MuiSvgIcon-root,
  .helpContainer .help .header svg{
    vertical-align: bottom;
    margin-right: 10px;
  }
  .helpContainer .help > .header{
    display: flex;
    align-items: center;
    color: #436389;
    font-size: 1rem;
    margin-left: 5px;
    padding-left: 25px;
  }
  .helpContainer .help li > div:not(.header){
    padding:5px;
  }
  .helpContainer .help > .header > svg{
    margin-right: 10px;
    color: #839DBD;
  }
  .helpContainer .help li > span > p:last-child{
    margin-bottom:0;
  }
  .helpContainer .help > p > ul{
    padding-left:0;
    font-size: 0.9rem;
  }
  .helpContainer .help  > p > ul > li{
    list-style:none;
    background-color:white;
    border: 1px solid rgba(0, 0, 0, 0.13);
    margin: .5rem 0;
    padding: 1rem;
    border-radius: 5px;
    hyphens: auto;
  }
  .helpContainer .help > p > ul > li .header{
    font-weight: 500;
    margin-bottom: 10px;
    color: rgba(0,0,0,.87);
  }
  .helpContainer .help > p > ul > li ul{
    padding-left: 22px;
    list-style-type: disc;
  }
  .helpContainer .help > p > ul > li ul > li{
    padding-top:5px;
  }

  .help > .MuiTypography-root > ul{
    padding-left:0;
    font-size: 0.9rem;
    margin:0;
}
.helpContainer .help  > .MuiTypography-root > ul > li{
  list-style:none;
  background-color:white;
  border: 1px solid rgba(0, 0, 0, 0.13);
  margin: .5rem 0;
  padding: 1rem;
  border-radius: 5px;
  hyphens: auto;
}
.help > .MuiTypography-root > ul > li .header{
    font-weight: 500;
    margin-bottom: 10px;
    color: rgba(0,0,0,.78);    
}
.help > .MuiTypography-root > ul > li > .header:first-child{
    font-size:1rem;
}
.help > .MuiTypography-root > ul > li b{
  font-weight: 500;
  color: rgba(0,0,0,.78);
}
.help > .MuiTypography-root > ul > li ul{
    padding-left: 22px;
    list-style-type: disc;
}
.help > .MuiTypography-root > ul > li ul > li{
    padding-top:5px;
}
.help > .MuiTypography-root > ul > li ol{
  padding-left: 22px;
}
.help > .MuiTypography-root > ul > li ol > li{
  padding-bottom:5px;
}
.help > .MuiTypography-root{
  height: calc(100% - 34px);
  margin-top: 12px;
  overflow-y: auto;
  padding-right: 5px;
}
.helpContainer .help:focus-within{
  background-color: #f6f8fa;
}
.helpContainer .help #helpContainerScrollPanel:focus-visible{
  outline: 2px solid var(--color-primary-border);
  border-radius: 3px;
  outline-offset: 3px;  
}