:root {
  --color-primary: #0C66E4; /*#0b57d0;*/
  --color-primary-border: #0C66E4;
  --color-primary-50: rgba(12, 102, 228, 0.5);
  --color-primary-70: rgba(12, 102, 228, 0.7);
  --color-primary-dark: #5c738f;
  --color-primary-light: rgba(12, 102, 228, 0.08);
  
  --color-hc-red: #cd1f19;
  --color-hc-green: #008758;
  --color-hc-orange: #cf4102;
  --color-hc-gray:#757575; /* rgba(0,0,0,.54);*/
  --color-hc-black: #212121; /* rgba(0,0,0,.87);*/
  --color-light-green: #54C793;  
  --color-light-orange: #ff9800;
  --color-light-red: #DE4747;
  
  
  --color-spa-light: #fb7fa7;
  --color-dca-light: #79a3ff;
  --color-eea-light: #1fd2ab;
  --color-daa-light: #ba68c8;
  --color-dv-light: #ff8a10;

  --color-spa: #fb7fa7; 
  --color-dca: #79a3ff;
  --color-eea: #1fd2ab;
  --color-daa: #ba68c8;
  --color-dv: #ff8a10;
  
  --color-spa-hc: #cb3968;
  --color-dca-hc: #4175cb;
  --color-eea-hc: #0e8168;
  --color-daa-hc: #8b4e96;
  --color-dv-hc: #B35C00;

  --color-price-background: #e5e5e5;
  --color-price: rgba(0,0,0,.87);
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#F9FAFB !important;
  scroll-padding-top: 56px;
}
button, body *{
  font-family: "Roboto", sans-serif;
}
.root{
  display: flex;
  background-color: rgb(250, 249, 248);
  width: 100%;
}
code, body code * {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
header > .MuiToolbar-root{
  min-height: 56px;
}
header > .MuiToolbar-root .subTitle{
  margin-left: 1rem;
  font-weight: 400;
  font-size: 1.0rem;
  color: var(--color-hc-gray);
}
.MuiTooltip-tooltip{
  background-color: rgba(47, 47, 47,.89);
}
main{
  width:calc(100% - 57px);
  min-height:100vh;
  padding:0;
}
.openDrawer main{
  width:calc(100% - 250px);
  padding:0;
}
div.App-drawerOpen-46 + main{
  width:calc(100% - 240px);
}
main > div{
  width:100%;
}
.MuiDrawer-root > .MuiDrawer-paper{
  background-color: #394b5f;
  /*background-color: #394b5fd4; */
    color: rgba(255,255,255,0.75);
   overflow: visible;
}
.MuiDrawer-root > .MuiDrawer-paper > .drawer_open > div:first-child{
  min-height: 56px;
  height: 56px;
}
.MuiDrawer-root + main > div:first-child{
  min-height: 56px;
}
.MuiDrawer-root > .MuiDrawer-paper .MuiTypography-body1{
  font-size:0.9rem;
  line-height:1.2;
}
.MuiDrawer-root > .MuiDrawer-paper .MuiList-root{
  overflow-x: hidden;
}
.MuiDrawer-root > .MuiDrawer-paper > div > .MuiList-root{
  overflow-y: auto;
  height: calc(100vh - 56px);
}
.MuiDrawer-root .MuiListItem-root > .MuiListItemIcon-root{
  min-width: 40px;
}
.MuiDrawer-root svg, .MuiDrawer-root svg rect{
  fill: rgba(255,255,255,0.4);
  color: rgba(255,255,255,0.4);
  font-size: 1.3rem;  
}
.MuiDrawer-root svg polyline{  
  stroke: rgba(255,255,255,0.4);
}
.MuiDrawer-root .selected{
  color: white;
}
.MuiDrawer-root .selected svg, .MuiDrawer-root .selected svg rect{
  fill: rgba(255,255,255,1);
  color: rgba(255,255,255,1);
}
.MuiDrawer-root .selected svg polyline{
  stroke: rgba(255,255,255,1);
}
.MuiDrawer-root .MuiListItem-root:hover,
.MuiDrawer-root .MuiListItem-root.Mui-focusVisible {
  /*background-color:#526579;*/
  background-color: rgba(255, 255, 255, 0.09);
  border-radius: 5px;
}
.MuiDrawer-root .MuiListItemButton-root:hover svg, 
.MuiDrawer-root .MuiListItemButton-root:hover svg rect,
.MuiDrawer-root .MuiListItemButton-root.Mui-focusVisible svg, 
.MuiDrawer-root .MuiListItemButton-root.Mui-focusVisible svg rect{
  fill: rgba(255,255,255,.6);
  color: rgba(255,255,255,.6);
}
.MuiDrawer-root .MuiListItemButton-root:hover svg polyline{
  stroke: rgba(255,255,255,0.6);
}
.MuiDrawer-root .MuiList-root .MuiListItem-root{
  padding: 0;
  margin: 1px 5px;
  width: calc(100% - 10px);
}
body.closedDrawer .MuiDrawer-root .MuiList-root .MuiListItem-root .openMenu{
  margin-left: 12px;
}
.MuiDrawer-root .MuiList-root .MuiListItemButton-root{
  padding-left: 11px;
  padding-top:7px;
  padding-bottom:7px;
  padding-right: 0;
}
.MuiDrawer-root .MuiList-root .MuiListItemButton-root:hover{
  background-color:transparent;
}
.MuiDrawer-paper .selected{
  background-color:#526579;
  border-radius: 5px;
  opacity: 1;
}

.MuiDrawer-root .smallToggle{
  position: absolute;
  right: -12px;
  bottom: 25px;
  background-color: #394b5f;
  border: 1px solid #526579;
  width: 24px;
  height: 24px;
  transition: transform .6s ease-in-out;
  transform: scale(0);
  z-index: 9;
}
.MuiDrawer-root:hover .smallToggle{
  transition: transform 0.3s ease-in-out;
}
.MuiDrawer-root .smallToggle:hover{
  background-color: #526579;
}
.MuiDrawer-root:hover .drawer_closed .smallToggle{
  transform: scale(1) rotate(180deg);
}
.MuiDrawer-root:hover .drawer_open .smallToggle{
  transform: scale(1) rotate(0deg);
}

.drawer_closed #companyLogo{
  max-height: 0;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.drawer_open .logoWrapper{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 2em;
}
.drawer_open #companyLogo{
  max-width: 100%;
  margin: 0 auto;
  display: block;
  max-height: 44px;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius:5px;
}
.MuiDrawer-root .drawer_closed .MuiCollapse-root{
  margin-left:0;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.MuiDrawer-root .drawer_open .MuiCollapse-root{
  margin-left:1rem;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.MuiDrawer-root .operatorsOnly{
  margin-left: 7px;
}
.MuiDrawer-root .operatorsOnly > .MuiSvgIcon-root{
  font-size: 1rem;
  position: relative;
  top: 2px;
  fill: rgba(255,255,255,0.4);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

.errorPanel{
  background-color: rgba(222, 71, 71, .04);
  border: 1px solid #DE4747;
  background-color: rgba(0,0,0,.02);
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  padding: 10px 5px;
  margin-bottom: 1rem;
  color: #DE4747;
  display: flex;
  position: relative;
  margin-bottom: 1.5em;
}
.errorPanel > div{
  margin:3px 1em 0;
}
.errorPanel > svg{
  margin-left:5px;
}
input:autofill, input:autofill:hover, input:autofill:focus, input:autofill::first-line,
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill::first-line {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
}
.snackbar .message{
  display: flex;
  align-items: center;
}
.snackbar .message > div{
  margin-top: 2px;
}
.snackbar .message > svg{
  margin-right: 1rem;
}
.MuiSvgIcon-root.bsLogo{
  width: 20px;
  height: 20px;
  color:#839DBD;
}
.MuiDrawer-root .MuiSvgIcon-root.bsLogo{
  padding: 2px 2px 2px 0;
  width: 1.3rem;
  height: 1.3rem
}
.MuiDrawer-root svg.service{
  width: 1.3rem;
  height: 1.3rem;
}
.MuiDrawer-root .footer{
  bottom: 10px;
  position: absolute;
  width: 100%;
}
.MuiDrawer-root .footer > img{
  margin-left: auto;
    margin-right: auto;
    display: block;
    width: 120px;
}
.MuiDrawer-root .drawer_closed .footer{
  display:none;
}
.MuiListItem-root > .MuiListItemIcon-root{
  min-width: 48px;
}
.MuiList-root.MuiList-padding{
  padding: 0;
}
.saveBackdrop{
  z-index: 9999 !important;
}
.customContainerItems{
  display: flex;
  align-items:center;
}
header fieldset{
  display: none;
}
header .MuiFormControl-root{
  margin:0;
}
header .MuiSelect-select:focus{
  background:none;
}
header .MuiOutlinedInput-root .MuiSvgIcon-root{
  color: rgba(0,0,0,.54);
}
header .MuiOutlinedInput-root:hover{
  background-color: rgba(0,0,0,.04);
}
header .profile > .MuiOutlinedInput-root{
  background-color:var(--color-primary-dark);
  color:white;
  margin: 0 .5rem;
}
header .profile > .MuiOutlinedInput-root .MuiSvgIcon-root{
  color: white;
}
header .profile > .MuiOutlinedInput-root:hover{
  background-color: rgba(131,157,189,0.7);
}
.preLoader{
  color:#839DBD;
  height:100vh;
  width: 100vw;
  display: block;
  font-size: 1.5rem;
  text-align: center;
  position: fixed;
  transition: opacity 350ms cubic-bezier(0.4, 0, 0.2, 1), width 0ms 400ms, height 0ms 400ms;
}
.preLoader.noDisplay{
  display: none;
}
.preLoader.hidden{
  opacity: 0;
  width:0;
  height:0;
}
.preLoader > div{
  margin-left:auto;
  margin-right:auto;
  display: block;
  width:300px;
  padding-top:30vh;
}
.preLoader .MuiSvgIcon-root.bsLogo{
  width:5rem;
  height:5rem;
  color:#839DBD;
}

.preLoader .spinner {
  text-align: center;
}

.preLoader .spinner > div {
  width: 18px;
  height: 18px;
  background-color: #839DBD;
  margin: 0 2px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
  animation: bouncedelay 1.4s infinite ease-in-out both;
}

.preLoader .spinner .bounce1 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.preLoader .spinner .bounce2 {
  -webkit-animation-delay: 0.96s;
  animation-delay: 0.96s;
}

.preLoader .spinner .bounce3 {
  -webkit-animation-delay: 1.12s;
  animation-delay: 1.12s;
}

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.preLoader .iconWrapper{
  position:relative;
}
.preLoader .iconWrapper > svg{
  -webkit-animation: buttonPress 1.0s 1 ease-in-out;
  animation: buttonPress 1.0s 1 ease-in-out;
}
.preLoader .shadow {
  width: 200px;
  height: 200px;
  background-color: #333;
  position:absolute;
  top:-50px;
  left:calc(50% - 100px);
  border-radius: 100%;
  -webkit-animation: scaleout 1.0s 1 ease-in-out;
  animation: scaleout 1.0s 1 ease-in-out;
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
  opacity:0;
}

@-webkit-keyframes buttonPress {
  0%, 100% {
     -webkit-transform: scale(1.0) }
  30% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes buttonPress {
  0%, 100%{
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  } 30% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@-webkit-keyframes scaleout {
  0% {
    -webkit-transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
.completeWrapper, 
.circularProgressWrapper{
  position: relative;
  display: inline-block;
}
.completeWrapper > .MuiCircularProgress-root, 
.circularProgressWrapper > .MuiCircularProgress-root{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
.circularProgressWrapper > .MuiButtonBase-root .MuiTypography-root{
  font-size:0.7rem;
  color:rgba(0,0,0,.87);
}
.circularProgressWrapper.big > .MuiButtonBase-root .MuiTypography-root{
  font-size:1.0rem;
}
.circularProgressWrapper > .progressCircle{
  position: absolute;
  top: calc(50% - 13px);
  left: calc(50% - 26px);
  background-color: rgba(255,255,255,.9);
  width:52px;
  height:30px;
  text-align: center;
}
.circularProgressWrapper > .progressCircle .MuiTypography-root{
  font-size:0.9rem;
  font-weight: 500;
  color: var(--color-primary);
  top:4px;
  left:2px;
  position: relative;
}
.circularProgressWrapper > .progressCircle .MuiCircularProgress-root{
  margin-left:0 !important;
}
.circularProgressWrapper .MuiLinearProgress-root{
  position: absolute;
  left: 0;
  top: 2px;
  width: 100%;
  border-radius: 5px;
}
.circularProgressWrapper .MuiLinearProgress-root .MuiLinearProgress-bar{
  border-radius: 5px;
}
.verifierIcon.small{
    width: 24px;
    height: 24px;
}
.verifierIcon.small > g > g{
  fill: #444;
}

.circularProgressWrapper .completed{
  background-color: #54C793;
  color: white;
  fill: white;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 13px);
  top: 2px;
  width: 1.3em;
  height: 1.3em;
  margin: 0;
  transform: scale(0);
  transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  max-height: 0;
  max-width: 0;
}
.circularProgressWrapper .completed.error{
    background-color: var(--color-light-red);
}
.circularProgressWrapper .completed.show{
  transform: scale(1);
  max-height: 100%;
  max-width: 100%;
  padding: 3px;
}
.circularProgressWrapper .completed.error{
  color: #DE4747;
  fill: #DE4747;
  background-color: white;
  padding: 0;
  border: none;
  box-shadow: none;
}



.root{
  position: relative;
}
.root > .loadingState {
  position: absolute;
  margin-left: calc(50% - 150px);
  margin-right: auto;
  display: block;
  width: 300px;
  margin-top:20vh;
  transition: transform 0.5s cubic-bezier(0,0,.2,1);
}
.root > .loadingState.hidden{
  transform: translateY(min(-35vh,-500px));
}
.root > .loadingState .MuiAvatar-root{
  height:100px;
  width:100px;
  margin-left: auto;
  margin-right: auto;
  background-color: #EEE;
  color: rgba(0,0,0,.42);
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.root > .loadingState .MuiAvatar-root .MuiSvgIcon-root{
  font-size:4rem;
}
.root > .loadingState .loadingProgress{
  height:110px !important;
  width:110px !important;
  position: absolute;
  top: -6px;
  left: calc(50% - 55px);
}
.root > .loadingState .header{
  font-size: 1.5rem;
  margin-top: 1rem;
  color: var(--color-hc-gray);
  text-align: center;
  display: block;
}
.root > .main{
  transition: opacity 0.5s cubic-bezier(0,0,.2,1);
  opacity: 1;
  width:100%;
}
.root > .main.hidden{
  transform: translateY(-500px);
  opacity: 0;
}

body::-webkit-scrollbar-track,
*::-webkit-scrollbar-track,
.MuiDialog-paperScrollPaper::-webkit-scrollbar-track,
.modelbuilder .specifyEntities .mainContent .description::-webkit-scrollbar-track,
.modelbuilder .help::-webkit-scrollbar-track
.apiDescription .navContainer::-webkit-scrollbar-track,
*::-webkit-scrollbar-track{
  background: #F9FAFB;
}
.MuiDrawer-paper::-webkit-scrollbar-track{
  background:#394b5f;
}
body::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb,
.MuiDialog-paperScrollPaper::-webkit-scrollbar-thumb,
.modelbuilder .specifyEntities .mainContent .description::-webkit-scrollbar-thumb,
.modelbuilder .help::-webkit-scrollbar-thumb,
.apiDescription .navContainer::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background-color: #a3acb9;
  border-radius: 6px;
  border: 1px solid #fff;
}
.MuiDrawer-root *::-webkit-scrollbar-thumb{
  border-color: #394b5f;
}
.MuiDrawer-paper::-webkit-scrollbar-thumb{
  background-color: #a3acb9;
  border-radius: 6px;
  border: 1px solid #394b5f;
}
body::-webkit-scrollbar,
*::-webkit-scrollbar,
.MuiDialog-paperScrollPaper::-webkit-scrollbar,
.MuiDrawer-paper::-webkit-scrollbar,
.modelbuilder .specifyEntities .mainContent .description::-webkit-scrollbar,
.modelbuilder .help::-webkit-scrollbar{
  width: 14px;
  height: 14px;
}
*::-webkit-scrollbar{
  width: 12px;
  height: 12px;
}
.apiDescription .navContainer::-webkit-scrollbar,
*:not(body)::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.closedDrawer .MuiDrawer-paper::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}
.MuiDrawer-paper::-webkit-scrollbar-corner,
*::-webkit-scrollbar-corner{
  display: none;
}

.main .headerCaption {
  color: rgba(0,0,0,.87);
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 1.1rem;
}
.main .MuiCardHeader-subheader{
  font-size: 0.75rem;
  height: 20px;
}
.main .MuiCardContent-root .MuiTypography-h5 {
  font-size: 1.3rem;
}
.main .MuiSpeedDialAction-tooltipPlacementLeft .MuiSpeedDialAction-staticTooltipLabel{
  white-space: nowrap;
}

.root.models .MuiBreadcrumbs-root,
.root.modelbuilder .MuiBreadcrumbs-root,
.root.shopRoot .MuiBreadcrumbs-root
{
  display:none;
}

.root .MuiBreadcrumbs-root{
  margin-bottom: 2rem;
}
.root .MuiBreadcrumbs-root .MuiSvgIcon-root{
  width:20px;
  height:20px;
  bottom: 2px;
  position: relative;
}
.root .MuiBreadcrumbs-root .MuiButton-root{
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
}
.root .MuiBreadcrumbs-root .MuiButtonBase-root.Mui-disabled{
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

body .fontRed{
  color: #f44336;
}
td.MuiTableCell-root.aligncenter{
  padding-right:26px;
}
td.MuiTableCell-root:last-child.aligncenter{
  padding-right:26px;
}
body .MuiTableCell-sizeSmall {
  padding: 5px 0px 3px 16px;
  line-height: 18px;
}
body .MuiButtonBase-root.MuiTableSortLabel-root:focus-visible {
  outline: 2px solid var(--color-primary-border);
  border-radius: 3px;
  outline-offset: 3px;
}

.MuiPopover-paper .MuiList-root .MuiListItemText-primary{
  font-size:1em;
}
.MuiPopover-paper .MuiList-root .MuiListItem-root{
  padding: 0;
}
.MuiPopover-paper .MuiList-root .MuiListItem-root .MuiSvgIcon-root{
  font-size: 1.5em;
}
.MuiPopover-paper .MuiList-root .MuiListItem-root .MuiCheckbox-root .MuiSvgIcon-root{
  font-size: 1.5rem;
}
.MuiPopover-paper .MuiList-root .MuiListItem-root > .MuiListItemIcon-root{
  min-width: 40px
}
.operatorsOnly{
  font-size: 0.8em;
  color: rgba(0,0,0,.43);
  margin-left: 5px;
}

.detailDialog{
  top: 56px !important;
  left: 58px !important;
  padding-left: 0;
  z-index:99 !important;
}
body.openDrawer .detailDialog{
  top: 56px !important;
  left: 250px !important;
  padding-left: 0;
}
@media(max-width:900px) {
  .detailDialog{
    left: 0 !important;
  }
}
.detailDialog .MuiDialog-paperFullScreen{
  box-shadow: none;
  padding:0 24px;
  background-color: rgb(250, 249, 248);
}
td > .progress,
.progressWrapper > .progress{
  background-color: #E0E0E0;
  border-radius: 2px;
}
td > .progress .bar,
.progressWrapper > .progress .bar{
  background-color: #54C793;
  color: #fff;
  font-weight: 400;
  border-radius: 2px;
}
td > .progress .bar > .data,
.progressWrapper > .progress .bar > .data{
  padding-left: 10px;
}
.errorSnackbar{
  background-color: #f44336 !important;
  margin-left:80px;
}
.errorSnackbar .message{
  display: flex;
  align-items: center;
}
.errorSnackbar .message > div{
  margin-top: 2px;
}
.errorSnackbar .message > svg{
  margin-right: 1rem;
}
.MuiFormHelperText-contained{
  hyphens: auto;
}

body.openDrawer .MuiSnackbar-anchorOriginBottomLeft{
  left:274px !important;
}
body.closedDrawer .MuiSnackbar-anchorOriginBottomLeft{
  left:81px !important;
}
.MuiSnackbarContent-message > .message{
  display: flex;
}
.MuiSnackbarContent-message > .message > svg{
  margin-right: 1rem;
}
.apexcharts-canvas{
  width:100% !important;
}
.confirmationDialog .warning {
  color: #DE4747;
}
.confirmationDialog b{
  font-weight: 500;
  color: black;
}

.pollingStatus{
  position: absolute;
  bottom: 20px;
  padding-left:20px;
  width:100%;
  overflow: hidden;
  background-color:#394b5f;
}
.pollingStatus .MuiCircularProgress-root{
  width: 24px !important;
  height: 24px !important;
}
.pollingStatus .message{
  white-space: pre-line;
  display: inline-block;
  padding-left: 20px;
  font-size: 0.8rem;
}
.pollingStatus .circularProgressWrapper{
  height: 30px;
  width: 30px;
}
.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}
.MuiTableCell-sizeSmall:last-child{
  padding-right: 16px;
}

.MuiAutocomplete-root .MuiAutocomplete-inputRoot{
  padding: 6px;
}
.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding: 4.5px 4px 4.5px 6px;
}

.MuiFormControl-root {
  margin-top: 8px;
  margin-bottom: 4px;
}
.MuiTab-root {
  min-width: 160px;
}
.MuiTab-labelIcon > *:first-child {
  margin-bottom: 6px;
}


.MuiCardActions-root > .icons{
  position: absolute;
  right: 15px;
  display: flex;
}

.MuiTableCell-paddingNone,
body .MuiTableCell-sizeSmall.MuiTableCell-paddingNone {
  padding: 0;
}
.MuiTableCell-paddingNone.cell_checkbox,
body .MuiTableCell-sizeSmall.MuiTableCell-paddingNone.cell_checkbox {
  padding-left: 8px;
}
.MuiTableCell-paddingNone:last-child{
  padding-right: 0;
  padding-left: 0;
}

.MuiDialog-root.MuiModal-root,
.MuiPopover-root,
.MuiTooltip-popper {
  z-index:10000;
}

@media screen and (min-width: 1200px){
	body.openDrawer div.root > header.MuiAppBar-root {
		width: calc(100% - 250px);
		margin-left: 250px;
	}
}

.root .emptyState{
  width:350px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2.5rem 0 2rem;
  transition: all 0.5s cubic-bezier(0,0,.2,1);
  position: absolute;
}
.root .emptyState.hidden{  
  transform: translateY(-500px) translateX(-1px);
  transition: transform 0.1s cubic-bezier(0,0,.2,1);
  opacity: 0;
}
.root .emptyState.show{  
  opacity: 1;
}
.root .emptyState .MuiAvatar-root{
  width:80px;
  height:80px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.root .emptyState .MuiAvatar-root > svg{
  font-size: 3.5rem;
}
.root .emptyState .header{
  font-size: 1.2rem;
  margin-top:1rem;
  margin-bottom:1rem;
}
.root .emptyState .description{
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  text-align: center;
}
.MuiPopperUnstyled-root{
  z-index: 999999;
}
.MuiListItemIcon-root{
  min-width: 46px;
}
.MuiListItemButton-root{
  font-size: 0.875rem;
  padding: 4px 16px;
}
.MuiAvatar-root > img{
  width: auto;
}
.MuiTableCell-sizeSmall > div > .user{
  font-size: 10px;
  color: rgba(0, 0, 0, 0.54);
  line-height: 10px;
}
.detailedIcon{
  position: relative;
  width: 24px;
}
.detailedIcon > svg{
  width: 0.85em;
  height: 0.85em;
}
.detailedIcon .detail{
  position: absolute;
  top: 11px;
  left: 11px;
  line-height: 16px;
  background-color: rgba(255, 255, 255, .8);
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.detailedIcon .detail > svg{
  width: 14px;
  height: 14px;
}
.tableToolbar .actions > div{
  display: flex;
  align-items: center;
}
.tableToolbar .actions > div .separator{
  display: inline-block;
  border-right: 1px solid rgba(224, 224, 224, 1);
  height: 25px;
  width: 1px;
  margin: 0 10px;
}


.primaryColor,
.MuiButton-textPrimary,
.MuiTabs-root .Mui-selected.MuiTab-textColorPrimary,
.MuiFormLabel-root.MuiInputLabel-root.Mui-focused,
.MuiCircularProgress-colorPrimary,
.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate,
.MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
.MuiButtonBase-root.MuiChip-root,
.MuiButtonBase-root.MuiChip-root .MuiChip-deleteIcon:hover,
.MuiTypography-root.MuiLink-root,
.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked{
  color: var(--color-primary);
}
.MuiButton-outlinedPrimary{
  color: var(--color-primary);
  border-color: var(--color-primary-50);
}
.primaryBackgroundColor,
.MuiBadge-badge,
.MuiButton-containedPrimary,
.MuiLinearProgress-barColorPrimary,
.MuiFab-primary,
.MuiTabs-root .MuiTabs-indicator,
.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
  background-color: var(--color-primary);
}
.primaryBorderColor,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: var(--color-primary-border);
}
.MuiButtonBase-root.MuiChip-root .MuiChip-deleteIcon{
  color: var(--color-primary-50);
}
.MuiButtonBase-root.MuiChip-root{
  border-color: var(--color-primary-50);
}
.MuiButtonBase-root.MuiToggleButton-root{
  text-transform: none;
  line-height: 12px;
}
.MuiButtonBase-root.MuiToggleButton-root.Mui-selected{
	color: var(--color-primary);
}
.root .naHint{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  max-width: min(750px,80%);
  margin-left: auto;
  margin-right: auto;
}
.root .naHint > div{
  font-size: 1.0rem;
  color: rgba(0,0,0,.63);
  text-align: justify;
  margin-top: 2rem;
}
.root .naHint h1{
  margin-top: 2rem;
  font-size: 1.5rem;
  margin-bottom: -1rem;
}
.root .naHint > button{
  margin-top: 3rem;
}
.root .naHint a{
  text-decoration: none;
  color: var(--color-primary);
  font-weight: 500;
}
.root .naHint a:hover{
  text-decoration: underline;
}
.root .naHint ol{
  padding-left: 16px;
  margin: 0;
}
.root .naHint ol > li{
  padding-left: 8px;
  padding-bottom: 4px;
}
body.openHelp .root.empty{
  padding-right: 400px;
}

table .showOnRowHover{
  display:none;
}
table tr:hover .showOnRowHover{
  display:inherit;
}

.MuiTableCell-sizeSmall .fileWrapper{
  display: flex;
  align-items: center;
  margin-left: -10px;
  position: relative;
}
.MuiTableCell-sizeSmall .fileWrapper > button{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  line-height: 14px;
  word-break: break-word;
  font-size: 0.875rem;
  margin-right: 10px;
}
.MuiTableCell-sizeSmall .fileWrapper > button:not(.Mui-disabled){
  color: var(--color-primary);
}
.MuiTableCell-sizeSmall .fileWrapper > button > svg{
  color: var(--color-primary);
  margin: 5px 15px 5px 5px;
}
.MuiLink-root.Mui-disabled{
  color: #737272 !important;
}
.MuiLink-root.Mui-disabled > svg{
  color: rgba(0,0,0,.17) !important;
}
.MuiLink-root.Mui-disabled:hover{
  text-decoration: none;
  cursor: default;
}
body header > .MuiToolbar-root > h6{
  overflow: visible;
}
.apexcharts-legend-series{
    max-width: 180px;
    display: flex;
}
.apexcharts-legend-series .apexcharts-legend-marker{
    flex-shrink: 0;
}
.apexcharts-legend-series  .apexcharts-legend-text{
    hyphens: auto;
}
.apexcharts-legend.apx-legend-position-right{
    top: 0px !important;
    display: flex;
    justify-content: flex-start !important;
}
.transferList{
  display: flex;
  margin-top: 0;
  width: 100%;
}

.transferList fieldset {
  align-items: center;
  border-color: rgba(0, 0, 0, .23);
  border-radius: 5px;
  border-width: 1px;
  display: flex;
  flex-flow: wrap;
  min-height: 100px;
  padding: 0;
  min-width: 200px;
}
.transferList fieldset > legend{
  color: rgba(0, 0, 0, .56);
  font-size: .75rem;
  margin-left: .75em;
}
.transferList fieldset .emptyMsg {
  color: rgba(0, 0, 0, .42);
  padding: 20px;
}
.transferList fieldset > div{
  height: 500px;
  width: 100%;
  overflow: auto;
}
.transferList .MuiCheckbox-root{
  padding: 0;
}
.transferList .buttons{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px;
  gap: 16px;
}
.bsTable th{
  padding-bottom: 4px;
  padding-top: 0;
  line-height: 18px;
}
.MuiTabs-root .operatorTab{
  min-height: 48px;
}
.MuiTabs-root .operatorTab > svg{
  height: 16px;
  width: 16px;
}
header .MuiLinearProgress-root.hideShadow{
	top: 30px;
}
header.hideShadow + div + main  > div > div > .MuiTabs-root {
  background-color: white;
  z-index: 999;
  box-shadow: 0 3px 0 -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 0 0 rgb(0 0 0 / 12%);
  position: fixed;
  top: 56px;
  width: calc(100% - 57px);
  min-height: 32px;
  height: 32px;
}
.openDrawer header.hideShadow + div + main  > div > div > .MuiTabs-root {
  width: calc(100% - 250px);
}
header.hideShadow + div + main  > div > div > .MuiTabs-root .MuiTab-root{
  min-height: 32px;
  height: 32px;
  padding-top: 6px;
  padding-bottom: 6px;
}